var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-nav-bar',{attrs:{"title":"修改密码","left-arrow":"","safe-area-inset-top":""},on:{"click-left":_vm.navBack}}),_c('van-field',{staticClass:"mt-10",attrs:{"type":_vm.passtype0 ? 'password' : 'text',"name":"原始密码","label":"原始密码","placeholder":"原始密码","rules":[{ required: true, message: '请填写原始密码' }],"right-icon":_vm.passtype0 ? 'closed-eye' : 'eye-o'},on:{"click-right-icon":_vm.viewpass0},model:{value:(_vm.orginpsw),callback:function ($$v) {_vm.orginpsw=$$v},expression:"orginpsw"}}),_c('van-field',{staticClass:"mt-10",attrs:{"type":_vm.passtype1 ? 'password' : 'text',"name":"新密码","label":"新密码","placeholder":"请输入新密码","right-icon":_vm.passtype1 ? 'closed-eye' : 'eye-o',"rules":[
      {
        pattern: /^[0-9a-zA-Z]{6,16}/,
        message: '请输入6~16位由数字或字母组成的密码'
      },
      { required: true, message: '请输入新密码' }
    ]},on:{"click-right-icon":_vm.viewpass1},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"type":_vm.passtype2 ? 'password' : 'text',"name":"确认新密码","label":"确认新密码","placeholder":"请再次输入新密码","right-icon":_vm.passtype2 ? 'closed-eye' : 'eye-o',"rules":[
      { required: true, message: '请再次输入新密码' },
      { validator: _vm.validator, message: '两次输入的密码不相同' }
    ]},on:{"click-right-icon":_vm.viewpass2},model:{value:(_vm.repeatpsw),callback:function ($$v) {_vm.repeatpsw=$$v},expression:"repeatpsw"}}),_c('div',{staticClass:"m16 mt-30"},[_c('van-button',{attrs:{"loading":_vm.isLogining,"round":"","block":"","type":"primary","native-type":"submit"}},[_vm._v(" 提交 ")]),_c('van-button',{staticClass:"mt16",attrs:{"loading":_vm.isLogining,"round":"","block":"","type":"info","native-type":"button"},on:{"click":_vm.onLoginOut}},[_vm._v(" 退出登录 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }