<template>
  <van-form @submit="onSubmit">
    <van-nav-bar
      title="修改密码"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-field
      v-model="orginpsw"
      :type="passtype0 ? 'password' : 'text'"
      name="原始密码"
      label="原始密码"
      placeholder="原始密码"
      :rules="[{ required: true, message: '请填写原始密码' }]"
      class="mt-10"
      :right-icon="passtype0 ? 'closed-eye' : 'eye-o'"
      @click-right-icon="viewpass0"
    />
    <van-field
      v-model="password"
      :type="passtype1 ? 'password' : 'text'"
      name="新密码"
      label="新密码"
      placeholder="请输入新密码"
      :right-icon="passtype1 ? 'closed-eye' : 'eye-o'"
      :rules="[
        {
          pattern: /^[0-9a-zA-Z]{6,16}/,
          message: '请输入6~16位由数字或字母组成的密码'
        },
        { required: true, message: '请输入新密码' }
      ]"
      class="mt-10"
      @click-right-icon="viewpass1"
    />
    <van-field
      v-model="repeatpsw"
      :type="passtype2 ? 'password' : 'text'"
      name="确认新密码"
      label="确认新密码"
      placeholder="请再次输入新密码"
      :right-icon="passtype2 ? 'closed-eye' : 'eye-o'"
      :rules="[
        { required: true, message: '请再次输入新密码' },
        { validator, message: '两次输入的密码不相同' }
      ]"
      @click-right-icon="viewpass2"
    />
    <div class="m16 mt-30">
      <van-button
        :loading="isLogining"
        round
        block
        type="primary"
        native-type="submit"
      >
        提交
      </van-button>
      <van-button
        :loading="isLogining"
        round
        block
        type="info"
        native-type="button"
        class="mt16"
        @click="onLoginOut"
      >
        退出登录
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { Divider, Form, Field, Toast, Dialog, NavBar, Button } from 'vant'
// eslint-disable-next-line no-unused-vars
import { updatepassword, getuserinfo, getDemoData } from '@/api/home'
import { mapActions } from 'vuex'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Form.name]: Form,
    [Divider.name]: Divider,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Field.name]: Field
  },
  data() {
    return {
      isLogining: false,
      passtype0: true,
      passtype1: true,
      passtype2: true,
      orginpsw: '',
      password: '',
      repeatpsw: ''
    }
  },
  methods: {
    ...mapActions(['loginOutAction']),
    viewpass0() {
      this.passtype0 = !this.passtype0
    },
    viewpass1() {
      this.passtype1 = !this.passtype1
    },
    viewpass2() {
      this.passtype2 = !this.passtype2
    },
    async onSubmit() {
      this.isLogining = true
      // const loading = this.$loading()
      try {
        await this.changepsw()
      } catch (err) {
        console.log(err)
        Toast(err)
      } finally {
        this.isLogining = false
      }
    },
    navBack() {
      this.$router.go(-1)
    },
    onLoginOut() {
      console.log('onLoginOut')
      this.loginOutAction()
      this.$router.push('/login')
    },
    validator() {
      return this.password === this.repeatpsw
    },
    async changepsw() {
      const passData = { password: this.orginpsw, newpassword: this.password }
      console.log(passData)
      const data = await updatepassword(passData)
      // Notify({
      //   message: '数据加载成功',
      //   type: 'success'
      // })
      console.log(data)
      if (data.status === 1) {
        console.log('修改成功')
        Dialog.confirm({
          title: '修改成功',
          message: '密码修改成功',
          showCancelButton: false,
          confirmButtonText: '我知道了'
        }).then(() => {
          // this.$router.go(-1)
        })
      } else {
        throw data.msg
      }
    }
  }
}
</script>

<style scoped>
.mt-10 {
  margin-top: 10px;
}
.m16 {
  margin: 16px;
}
.mt16 {
  margin-top: 16px;
}
.mt-30 {
  margin-top: 30px;
}
</style>
